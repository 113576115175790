// -----------------------------------------------------------------------------
// BODY
// -----------------------------------------------------------------------------

body {
  margin: 0;
  background: $bgcolor-body;
  font-family: $font-body;

  &:not(.auth) #smartbanner { display: none !important; }
}

// -----------------------------------------------------------------------------
// MAIN HEADER
// -----------------------------------------------------------------------------

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 72px;
  background: $bgcolor-white;
  border-bottom: 1px solid $outlinecolor-light;
  font-family: $font-heading;

  > div {
    padding: 0 0 0 20px;
    max-width: 1145px;
    height: 100%;
  }
}

.main-navigation {
  font-family: 'Open Sans', Sans-serif;
  padding: 0 20px 0 0;
  height: 100%;

  ul {
    vertical-align: bottom;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  img { vertical-align: middle; }

  i { line-height: 72px; }

  li {
    display: inline-block;
    line-height: 72px;
    height: 72px;
  }

  li > a {
    display: block;
    padding: 0 22px;
    line-height: 72px;
    font-size: 16px;
    text-decoration: none;
    color: $textcolor-dark;

    &:hover, &:focus, &.active {
      background-color: $bgcolor-lightmedium;

      &.icon-link {
        background-color: transparent;
        color: $textcolor-red;
      }
    }

    &.icon-link { line-height: 1rem; }
  }

  .logo {
    padding: 0 30px 0 0;

    img {
      border: none;
    }

    &:hover { background-color: transparent; }
  }

  i { font-size: 24px; }
}

// -----------------------------------------------------------------------------
// SIDEBAR
// -----------------------------------------------------------------------------

.sidebar {
  position: fixed;
  z-index: 16;
  top: 81px;
  width: 210px;

  .profile-presentation {
    background: $bgcolor-white;
    outline: 1px solid $outlinecolor-light;
    min-height: 310px;
  }

  .avatar-wrapper {
    height: 210px;
    background: $bgcolor-iceblue;
    line-height: 0;
  }

  .avatar {
    width: 100%;
    height: auto;
    background: $bgcolor-white;
  }

  .name {
    margin: 30px 10px 10px;
    min-height: 18px;
    word-wrap: break-word;
    line-height: 1;
    font-size: 1.1rem;
    font-family: $font-heading;
    font-weight: 700;
    color: $textcolor-dark;
    text-align: center;

    a { color: $textcolor-dark; }
  }

  .country {
    display: block;
    margin: 0;
    min-height: 16px;
    font-family: $font-body;
    font-size: 14px;
    color: $textcolor-medium;
  }

  .feedback {
    position: relative;
    margin-top: 15px;
    font-family: $font-body;
    font-weight: normal;
    font-size: 1rem;
    font-variant: small-caps;
    text-transform: lowercase;

    a {
      color: $textcolor-medium;

      &:hover { color: $textcolor-dark; }
    }
  }
}

// -----------------------------------------------------------------------------
// CONTENT CONTAINERS
// -----------------------------------------------------------------------------

.main-container {
  margin: 81px auto 200px;
  padding: 0 20px 0 250px;
  max-width: $base-width;
  min-height: 400px;
}

.main-container--full {
  @extend .main-container;
  padding: 0 20px;
}

.content-plate {
  position: relative;
  box-sizing: border-box;
  background: $bgcolor-white;
  border: 1px solid $outlinecolor-light;
}

.content-head {
  height: 75px;
  border-bottom: 1px solid $bordercolor-light;
}

.content-body {
  padding: 25px 10px 24px;
  font-size: 0.9rem;
  background: #fff;

  @include mobile {
    font-size: 0.7rem;
  }

  p, h1, h2, h3, h4, h5, h6 {
    padding: 0 30px;
  }
}

.content-caption {
  @extend .content-body;
  border-top: 1px solid $bordercolor-light;
}

.caption--empty {
  color: $textcolor-light;
}

.content-foot {
  height: 74px;
  border-top: 1px solid $bordercolor-light;
}

.content-head, .content-foot {
  color: $textcolor-medium;

  h1 {
    margin: 0 0 0 10px;
    padding: 0;
    font-size: 20px;
    line-height: 75px;

    &:before {
      display: inline-block;
      margin-right: 30px;
      content: ".";
      line-height: 75px;
      font-size: 0;
    }
  }

  .back {
    float: left;
    display: inline-block;
    padding: 0 25px;
    height: 100%;
    border-right: 1px solid $bordercolor-light;
    color: $textcolor-dark;

    &:hover { color: $textcolor-red; }

    i {
      font-size: 24px;
      line-height: 77px;
    }
  }
}

.content-foot {
  h1, .back, .back i { line-height: 74px; }
  .back { font-family: $font-heading; font-weight: 700; }
  .back, .back i { font-size: 14px; }
}

// -----------------------------------------------------------------------------
// PUSH TO BUY CLIP
// -----------------------------------------------------------------------------

.clip-push {
  .left { width: 200px; }

  p {
    margin: 0;
    padding: 25px 0;
    line-height: 1.5em;
  }
}

// -----------------------------------------------------------------------------
// CALENDAR
// -----------------------------------------------------------------------------

.calendar-wrapper {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 325px;
  padding: 50px 0;
  background: $bgcolor-white;
  box-shadow: 0 -12px 26px rgba(0,0,0, 0.16);

  &.calendar-open {
    transition: all .4s;

    .calendar-nav { box-shadow: 0 2px 4px rgba(29,29,29, .2); }
  }

  &.calendar-close {
    height: 0;
    padding: 0;
    transition: all .4s;

    ul { display: none; }
  }

  .calendar-nav {
    position: absolute;
    top: -85px;
    right: 0;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background: #e26060;
    color: #fff;
    box-shadow: 0 3px 4px rgba(29,29,29, .75);
    cursor: pointer;
    font-size: 35px;
    line-height: 70px;

    &.icon-calendar { top: -95px; }
  }

  .year {
    width: 150px;
    margin-top: 62px;
    font: 36px/1 $font-heading-sub; font-weight: 700;

    li {
      margin-bottom: 18px;
      border-bottom: 3px transparent solid;

      &.selected { border-color: $bgcolor-red; }
    }
  }

  .month  {
    width: 280px;
    text-transform: uppercase;
    margin-top: 43px;

    li {
      float: left;
      width: 85px;
      padding: 20px 0 7px;
      text-align: center;
    }
  }

  .day {
    width: 525px;
    padding-left: 86px;
    margin-left: 37px;
    border-left: 1px solid $outlinecolor-light;

    li {
      width: 75px;
      padding: 20px 0 7px;
      box-sizing: border-box;

      &.inactive { color: $bordercolor-light; }

      &.title { padding: 0 0 29px; }
    }
  }

  .calendar {
    position: relative;
    margin: 0 auto;
    padding: 0 20px;
    max-width: $base-width+230px;
    text-align: center;
    font: 14px/1 $font-heading; font-weight: 700;

    ul {
      list-style: none;
      float: left;

      li { float: left; }

      li > span {
        min-width: 27px;
        display: inline-block;
        padding-bottom: 10px;
        border-bottom: 3px transparent solid;
      }

      li.selected { color: $bgcolor-red; }

      .have-moment {
        cursor: pointer;

        > span { border-color: $bgcolor-red; }

        &:hover { color: $bgcolor-red; }
      }
    }
  }
}

// -----------------------------------------------------------------------------
// USEFUL MODULES
// -----------------------------------------------------------------------------

.progressbar {
  position: relative;
  height: 15px;
  border: solid 1px $bordercolor-green;
  border-radius: 2px;

  .progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background: $bgcolor-green;

    &.light { background: $bgcolor-medium; }
  }
}

.btn {
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  border: solid 1px #000;
  border-radius: 3px;
  line-height: 1rem;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

.hand {
  cursor: pointer;
}
