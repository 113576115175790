.settings-form {
  .input {
    padding: 40px;
    border-top: 1px solid $bordercolor-light;

    &:first-child { border-top: none; }
  }

  .input-container {
    margin: 0 40px 0 140px;

    &.select { margin-right: 0; }

    .fancy-select {
      display: block;
      width: 100%;
    }
  }

  input[type=text], input[type=password], input[type=email], input[type=number] {
    width: 100%;
    font-size: 14px;

    &:hover, &:focus { background: $bgcolor-light; }
  }

  .gender select { width: 130%; }

  label, .password-container a {
    float: left;
    display: inline-block;
    vertical-align: top;
    width: 135px;
    line-height: 70px;
    font-size: 16px;
    font-family: $font-heading; font-weight: 700;
  }

  .custom-select {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    height: 40px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.428571429;
    color: $textcolor-dark;
    background-color: $bgcolor-white;
    background-image: none;
    border: 1px solid $bordercolor-gray;
    border-radius: 4px;
  }

  .error {
    padding: 20px 0 0 140px;
    font-size: 0.9rem;
  }

  .avatar-wrap {
    label { float: none; }
  }

  .avatar-container {
    margin-left: -11px;

    .avatar-img {
      float: left;
      position: relative;
      display: inline-block;
      margin-right: 30px;
      width: 145px;
      height: 145px;

      .loader {
        position: absolute;
        top: 11px;
        left: 11px;
        width: 134px;
        height: 134px;
        background: rgba(255, 255, 255, 0.9);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -8px;
          margin-left: -8px;
        }
      }

      .img {
        padding: 10px;
        border: solid 1px $bordercolor-light;
        width: 134px;
        height: 134px;
      }
    }
  }

  .middle-container {
    width: auto;
    height: 145px;
  }

  .upload-avatar {
    display: inline-block;
    position: relative;

    button {
      padding: 8px 20px;
      height: 35px;
      position: relative;

      &:focus { outline: none; }

      .caret {
        width: 0;
        height: 0;
        display: inline-block;
        margin-left: 25px;
        top: -2px;
        position: relative;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: $bgcolor-white transparent transparent transparent;
      }
    }

    ul {
      position: absolute;
      top: 45px;
      right: 2px;
      left: 2px;
      margin: 0;
      padding: 0;
      background: $bgcolor-verydark;
      box-shadow: 1px 0 3px 1px $shadowcolor-medium;
      list-style: none;

      &:after {
        content: " ";
        width: 0;
        height: 0;
        position: absolute;
        right: 13px;
        top: -9px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $bgcolor-verydark transparent;
      }

      li {
        border-bottom: solid 1px $bordercolor-dark;
        font-size: 0.85rem;

        &:last-child { border-bottom: none; }

        &:hover {
          background: $bgcolor-dark;
          a { color: $textcolor-white; }
        }

        a {
          display: block;
          padding: 20px 20px;
          color: $textcolor-light;
        }
      }
    }
  }

  .password-container {
    &.active {
      background: $bgcolor-light;

      label { width: 165px; }
      .error { padding-left: 170px; }
    }

    a {
      float: right;
      width: auto;
    }

    .input-container {
      margin: 0 40px 0 165px;

      input { max-width: 400px; }
    }

    .password-input-container { margin: 0 200px 0 140px; }

    .input {
      padding: 10px 0;
      border-top: none;

      &:first-child { padding-top: 0; }
    }
  }

  #save-settings {
    position: relative;
    width: 160px;
    white-space: nowrap;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -8px;
      margin-left: -8px;
    }
  }

  .cancel { margin-left: 20px; }
}
