// -----------------------------------------------------------------------------
// FONT FACE INCLUDES
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'Colfax Medium';
  src: url(/assets/fonts/ColfaxWebMedium.eot);
  src: url(/assets/fonts/ColfaxWebMedium.eot?#iefix) format('embedded-opentype'), url(/assets/fonts/ColfaxWebMedium.woff) format('woff');
}

@font-face {
  font-family: 'icomoon';
  src: url(/assets/fonts/icomoon.eot?-o95o68);
  src: url(/assets/fonts/icomoon.eot?#iefix-o95o68) format('embedded-opentype'), url(/assets/fonts/icomoon.woff?-o95o68) format('woff'), url(/assets/fonts/icomoon.ttf?-o95o68) format('truetype'), url(/assets/fonts/icomoon.svg?-o95o68#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

// -----------------------------------------------------------------------------
// ICOMOON ICON FONT
// -----------------------------------------------------------------------------

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account:before { content: "\e600"; }
.icon-arrow_left:before { content: "\e601"; }
.icon-arrow_right:before { content: "\e602"; }
.icon-close:before { content: "\e603"; }
.icon-delete:before { content: "\e604"; }
.icon-logout:before { content: "\e605"; }
.icon-more:before { content: "\e606"; }
.icon-share:before { content: "\e607"; }
.icon-download2:before { content: "\e608"; }
.icon-download3:before { content: "\e609"; }
.icon-check:before { content: "\e60a"; }
.icon-password:before { content: "\e60b"; }
.icon-mail:before { content: "\e60c"; }
.icon-dbltap:before { content: "\e60d"; }
.icon-star:before { content: "\e60e"; }
.icon-unstar:before { content: "\e60f"; }
.icon-unstar2:before { content: "\e610"; }
.icon-unstar3:before { content: "\e611"; }
.icon-calendar:before { content: "\e612"; }
.icon-arrow_down:before { content: "\e613"; }
.icon-split:before { content: "\e614"; }
.icon-report:before { content: "\e616"; }
.icon-heart:before { content: "\e615"; }
.icon-scrubr:before { content: "\e617"; }
.icon-checkmark:before { content: "\e61b"; }
.icon-play:before { content: "\e617"; }
.icon-pause:before { content: "\e618"; }
.icon-tag:before { content: "\e618"; }
.icon-tag2:before { content: "\e618"; }
.icon-open_in_moment:before { content: "\e619"; }
.icon-notification:before { content: "\e61a"; }

.svg-share {
  background: url('/assets/img/share.svg');
  width: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
}

.icon--play:before {
  content: '';
  background: url("/assets/img/video_play.svg") center center no-repeat;
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svg-link {
  background: url('/assets/img/link.svg');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100px;
}

.svg-facebook {
  background: url('/assets/img/facebook.svg');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100px;
}

.svg-twitter {
  background: url('/assets/img/twitter.svg');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100px;
}

.ir {
  overflow: hidden;
  text-indent: -1000em;
  text-align: left;
}

// -----------------------------------------------------------------------------
// TEXT FORMATTING
// -----------------------------------------------------------------------------

.align-left { text-align: left; }
.align-right { text-align: right; }
.align-center { text-align: center; }

.bold {
  font-family: $font-heading;
  font-weight: 700;
}

h1, h2, h4, h4, h5, h6 {
  text-align: left;
  font-weight: normal;
}

h1 {
  font-family: $font-heading;
  font-weight: 700;
  color: $textcolor-dark;
}

time {
  font-family: $font-heading;
  font-weight: 700;
}

// -----------------------------------------------------------------------------
// LINKS
// -----------------------------------------------------------------------------

a {
  cursor: pointer;
  color: $textcolor-red;
  text-decoration: none;
  position: relative;

  &:hover, &:focus { color: $textcolor-alternate-red; }
}


// -----------------------------------------------------------------------------
// TEXT COLORS
// -----------------------------------------------------------------------------

.color-red { color: $textcolor-red; }
.color-light { color: $textcolor-light; }
