// -----------------------------------------------------------------------------
// BASE
// -----------------------------------------------------------------------------

$base-width: 897px;
$sidebar-width: 210px;

$font-heading: 'Helvetica Neue', Helvetica, sans-serif; //'Colfax Medium', Helvetica, sans-serif;
$font-heading-sub: 'Helvetica Neue', Helvetica, sans-serif; //'Colfax', 'Colfax Medium', Helvetica, sans-serif;

$font-body: 'Helvetica Neue', Helvetica, sans-serif;

// -----------------------------------------------------------------------------
// BACKGROUND COLORS
// -----------------------------------------------------------------------------

// @TODO Suggestion to run all color values throw http://chir.ag/projects/name-that-color/#EEDEDA
// This would make it much easier to find colors that are to similar and shouldn't be use both.

$bgcolor-body: #f2f3f4;

$bgcolor-white: #fff;
$bgcolor-light: #fbfbfb;
$bgcolor-lightmedium: #f5f6f6;
$bgcolor-medium: #e9eef2;
$bgcolor-dark: #343e49;
$bgcolor-verydark: #272b32;

$bgcolor-gray: #ccc;

$bgcolor-green: #21c190;
$bgcolor-iceblue: #ecf0f4;
$bgcolor-red: #e45f5d;

$bgcolor-alternate-red: #ee615f;

// -----------------------------------------------------------------------------
// BORDER COLORS
// -----------------------------------------------------------------------------

$bordercolor-white: #fff;
$bordercolor-light: #e5e5e5;
$bordercolor-medium: #bec6cd;
$bordercolor-dark: #363c45;

$bordercolor-gray: #ccc;

$bordercolor-green: #21c190;
$bordercolor-red: #c64c4a;
$bordercolor-darkred: #a53232;

$bordercolor-scrub: #dde7ed;

// -----------------------------------------------------------------------------
// OUTLINES AND SHADOWS
// -----------------------------------------------------------------------------

$outlinecolor-light: #e9e9ea;

$shadowcolor-medium: #ccc;

// -----------------------------------------------------------------------------
// TEXT COLORS
// -----------------------------------------------------------------------------

$textcolor-white: #fff;
$textcolor-verylight: #dee2e6;
$textcolor-light: #bec6cd;
$textcolor-medium: #707d8d;
$textcolor-dark: #3f4854;
$textcolor-black: #000;

$textcolor-green: #21c190;
$textcolor-red: #e45f5d;
$textcolor-alternate-red: #ee615f;
$textcolor-darkred: #a53232;
