.favorites {
  .moment-nav--video {
    display: none;
  }

  .video-modal .background {
    display: none;
  }

  .modal, .modal .content {
    position: absolute;
  }
}
