/* Target ios devices but not android */
.device-ios  {
  .icon-download2 { display: none !important; } /* Zip download doesn't work on ipad */
}

@media (max-width: 1023px) {

  .main-container { padding-left: 20px; max-width: 100%; }

  .moments-wrapper > div {
    max-width: inherit !important;
    margin: 0 auto !important;
    padding: 12px 20px 0 20px !important;
  }

  .sidebar {
    width: 68px;
    height: 68px;
    border: none;
    overflow: hidden;
    min-height: 0;
    top: 0;
    right: 20px;
    border-width: 0 1px;

    .avatar-wrapper { height: 68px; }

    .name, .country { display: none; }
  }

  .main-navigation ul.right { margin-right: 70px; }

  .notice ul { padding: 0 80px 0 180px; }

  .moment-nav { margin-left: -100px; }
}

@media (max-width: 940px) {
  .trim span { display: none; }

  .modal.large {
    .content { width: 720px; }
    .mergable-moments li {
      width: 196px;

      .moment { height: 90px; }
    }
  }
}

@media (max-device-width: 1025px) {
  .photo-grid .item .select { opacity: 1 !important; }

  input[type="checkbox"]::after { top: 1px; }
  input[type="checkbox"]:checked { background-position: 0 0, 44px 0; }

  .moment-showcase:hover { background-color: $bgcolor-white; }

  .toolset i:hover { color: $textcolor-verylight; }
}

@media (max-width: 1023px) {
  .calendar-wrapper {
    padding: 0;
    height: auto;

    .year, .month { margin: 0; }

    .calendar-nav {
      top: -122px;
      right: 30px;

      &.icon-arrow_down { top: -40px; }
    }

    .day {
      margin: 0;
      padding: 0;
      border: none;
      overflow: hidden;
    }

    .calendar {
      padding: 40px 20px;

      .year {
        padding-left: 20px;
        max-width: 100px;
        font-size: 26px;

        li { display: block; }
      }

      .month {
        float: none;
        width: auto;
        margin-left: 150px;
        overflow: hidden;

        li {
          padding-top: 0;
          margin-bottom: 10px;
        }
      }

      .day {
        float: none;
        border-top: 1px solid $outlinecolor-light;
        margin: 40px auto;
        padding-top: 40px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .tools .tools-menu ul { left: -122px; }
  .tools .tools-menu ul:after { right: 34px; }
}

@media (max-width: 1150px) {
  .calendar-wrapper .day {
    padding-left: 10px;
    margin-left: 0;
  }

  .calendar-wrapper .calendar-nav { right: 7px; }
}

/* Mobile views */
@media (max-width: 767px) {

  header > div > nav ul { padding: 0 }
  header > div > nav ul a { font-size: 12px; }

  .sidebar { display: none; }

  header {
    height: 60px;

    > div {
      padding: 0;
    }
  }

  /* Remove padding on the sides of moments */
  .moments-wrapper > div {
    padding: 12px 0 0 0 !important;
  }

  .main-navigation {
    padding: 0 5px;

    .logo {
      padding: 0;
    }

    li {
      height: 60px;
      line-height: 60px;
    }

    img {
      width: 20px;
      top: -8px;
      position: relative;
    }

    i {
      line-height: 60px;
      height: 60px;
      font-size: 16px;
    }

    a {
      height: 60px;
      line-height: 60px;
      padding: 0 5px;
      font-size: 12px;
    }

    ul.right {
      position: absolute;
      top: 0;
      right: 10px;
      margin: 0;
    }
  }

  .main-container {
    padding: 0;
    margin: 80px auto 200px;
  }

  .calendar { display: none; }
  .modal .content { width: 100%; }

}
