/* -----------------------------------------------------------------------------
 * Filters
 *
 * Each filter will contain a description and a animated checkbox that toggles
 * on/off.
 -----------------------------------------------------------------------------*/

.filters {
  list-style: none;
  margin: 0 30px;
}

.filters--head {
  $filters-head-height: 75px;

  float: right;
  height: $filters-head-height;
  line-height: $filters-head-height;

  @include mobile {
    display: none;
  }
}

.filters__filter {
  padding-right: 10px;

  span {
    padding-right: 10px;
  }

  label {
    position: relative;
    height: 74px;
    line-height: 74px;
  }

  .checkbox {
    top: 23px;
  }
}

/* -----------------------------------------------------------------------------
 * Tools
 *
 * A tools menu for moments
 -----------------------------------------------------------------------------*/

.tools {
  $height: 74px;

  padding: 0 20px;
  height: $height;
  border-left: solid 1px $bordercolor-light;
  font-size: 30px;
  line-height: $height;

  time {
    font-size: .47em;
    line-height: $height;
    display: block;
  }

  .icon-more {
    color: $textcolor-dark;
    line-height: $height;
  }

  @include mobile {
    height: 40px;
    line-height: 30px;
    font-size: 16px;
    padding: 0 15px;

    .icon-more {
      line-height: 40px;
    }
  }
}

.tools__menu {
  position: relative;
  cursor: pointer;
  height: 74px;
  line-height: 74px;
  display: block;

  i:hover, &.selected i { color: $textcolor-alternate-red; }

  &.selected {
    > ul { display: inherit; }
  }

  ul {
    display: none;
    position: absolute;
    z-index: 90;
    top: 55px;
    left: -142px;
    margin: 0;
    padding: 0;
    width: 220px;
    background: $bgcolor-verydark;
    border: solid 1px $bordercolor-dark;
    border-width: 1px 1px 0 1px;
    font-family: $font-body;
    font-size: 16px;

    &:after {
      position: absolute;
      z-index: 91;
      right: 54px;
      top: -9px;
      width: 0;
      height: 0;
      content: " ";
      border-color: transparent transparent $bgcolor-verydark transparent;
      border-style: solid;
      border-width: 0 10px 10px 10px;
    }

    li {
      display: block;
      margin: 0;
      padding: 0 23px;
      border-bottom: 1px solid $bordercolor-dark;
      line-height: 55px;
      font-size: 14px;
      color: $textcolor-light;

      i {
        font-size: 16px;
        color: $textcolor-light;
      }

      &:hover {
        background: $bgcolor-dark;
        color: $textcolor-white;
      }

      &.disabled, &.disabled:hover {
        background: transparent;
        color: $textcolor-medium;
        cursor: default;
      }
    }
  }

  &:hover { color: $textcolor-alternate-red; }

  @include mobile {
    height: 40px;
    line-height: 40px;

    ul {
      right: 0;
      left: auto;

      &:after {
        right: 0;
      }
    }
  }
}

.tools--foot {
  border: none;
  position: absolute;
  right: 0;
  bottom: -4px;

  @include mobile {
    bottom: 3px;
  }

  .tools__menu {
    ul {
      top: inherit;
      bottom: 55px;
      left: -142px;

      &:after {
        top: auto;
        bottom: -9px;
        border-width: 10px 10px 0 10px;
        border-color: $bgcolor-verydark transparent transparent transparent
      }

      @include mobile {
        right: 0;
        left: auto;
      }
    }
  }
}

.toolset--head.toolset--public-moments {
  time  {
    font: normal .875em $font-body;
    color: $textcolor-light;
  }
}

/* -----------------------------------------------------------------------------
 * Tools
 *
 * A tools menu for moments
 -----------------------------------------------------------------------------*/

.photo-filter {
  $height: 74px;
  $width: 160px;

  height: $height;
  width: $width + 40px;
  border-left: solid 1px $bordercolor-light;
  position: relative;


  font-size: 14px;
  font-weight: normal;

  cursor: pointer;

  @include mobile {
    height: 40px;
    line-height: 30px;
    font-size: 16px;
    padding: 0 15px;}

  &__selected {
    padding: 0 30px 0 20px;
    line-height: $height;
    display: inline-block;}

  &__caret {
    width: 0;
    height: 0;
    display: block;
    margin-top: -4px;
    top: 50%;
    right:10px;
    position: absolute;
    border-style: solid;
    border-color: transparent;
    border-top-color: $bordercolor-dark;
    border-width: 6px 6px 0 6px;
  }

  &:hover &__caret, &--open &__caret {
    border-top-color: $textcolor-alternate-red;}
  &--open &__caret {transform: rotate(180deg);}

  &__item {
    display: block;
    margin: 0;
    padding: 0 20px;
    border-bottom: 1px solid $bordercolor-light;
    line-height: 55px;
    height: 55px;
    font-size: 14px;

    width: $width;
    clear: both;

    &:hover { background: $bgcolor-lightmedium; }
  }

  &__menu {
    background: #FFFFFF;
    border: solid 1px $bordercolor-light;
    font-family: $font-body;
    font-size: 11px;

    display: block;

    position: absolute;
    top: $height;
    left: -1px;
    z-index: 90;

    &:after {
      position: absolute;
      z-index: 91;
      right: 50%;
      top: -10px;
      width: 0;
      height: 0;
      content: " ";
      border-color: transparent transparent $bordercolor-light transparent;
      border-style: solid;
      border-width: 0 10px 10px 10px;
    }

    @include mobile {
      height: 40px;
      line-height: 40px;

      ul {
        right: 0;
        left: auto;

        &:after {
          right: 0;
        }
      }
    }
  }
}
/* -----------------------------------------------------------------------------
 * Toolset
 *
 * A toolset acts as a wrapper for different kinds of content. It will exist in
 * the top or bottom of a moment.
 -----------------------------------------------------------------------------*/

.toolset {
  position: relative;
  box-sizing: border-box;
  font-family: $font-heading; font-weight: 700;
  color: $textcolor-dark;
  font-size: 14px;
  height: 75px;

  i {
    display: inline-block;
    cursor: pointer;
    color: $textcolor-dark;

    height: 75px;
    line-height: 74px;

    user-select: none;

    &:after { display: none; }

    &.disabled {
      cursor: normal;
      transition: all .2s;
      color: $textcolor-verylight;
    }

    .tooltip { bottom: -8px; }
  }

  b {
    font-family: $font-body;
    font-weight: 300;
    color: $textcolor-light;
  }

  img {
    width: 54px;
    height: 54px;
    margin: 10px 10px 0 0;
    float: left;
  }

  h1 {
    margin: 0 0 0 30px;
    padding: 0;
    font-size: 1.43em;
    line-height: 75px;
  }

  @include mobile {
    font-size: 12px;;
    height: 40px;

    i {
      height: 40px;
      line-height: 40px;
    }

    img {
      width: 30px;
      height: 30px;
      margin: 5px 5px 0 5px;
    }

    h1 {
      line-height: 40px;
      margin: 0 0 0 5px;
    }
  }
}

.toolset__middle {
  float: left;
  width: auto;
  max-width: 470px;
  font-size: 16px;
  margin-left: 10px;

  time {
    font-size: 1em;
  }

  p {
    color: $textcolor-medium;
    margin: 5px 0 0;
    font: .75em $font-body;
  }

  .toolset__middle--name {
    font-family: $font-heading; font-weight: 700;
    font-size: .875em;
    color: $textcolor-dark;
  }

  @include mobile {
    font-size: 10px;
  }
}

.toolset__back {
  float: left;
  display: inline-block;
  padding: 0 25px;
  height: 100%;
  color: $textcolor-dark;

  &:hover i {
    color: $textcolor-red;
  }

  @include mobile {
    padding: 0 15px;
  }
}

.toolset__back--head {
  border-right: 1px solid $bordercolor-light;
  margin-right: 10px;

  i {
    font-size: 24px;
    line-height: 77px;

    @include mobile {
      line-height: 40px;
      font-size: 12px;
    }
  }
}

.toolset__back--foot {
  font-size: 14px;
  margin-left: 10px;

  @include mobile {
    font-size: 10px;
    margin-left: 0;
  }
}

.toolset--head {
  border-bottom: 1px solid $bordercolor-light;

  time {
    margin-right: 10px;
    font-family: $font-body;
    font-weight: normal;
  }
}

.toolset--foot { border-top: 1px solid $bordercolor-light; }
