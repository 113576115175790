// -----------------------------------------------------------------------------
// Comment listing
// -----------------------------------------------------------------------------

.comments {
  padding: 10px;
}

.comments__count {
  color: $textcolor-light;
  cursor: pointer;
  margin-top: 0;

  &:hover {
    color: $textcolor-red;
  }
}

.comment {
  min-height: 30px;
  font-family: $font-body;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  overflow: hidden;

  &:hover .action { visibility: visible }

  > a {
    display: block;
  }

  img {
    float: left;
    margin-top: 9px;
  }

  p {
    margin: 0 0 1rem 40px;
    max-width: 500px;
  }

  i {
    line-height: 26px;
    font-size: 14px;
    color: $textcolor-light;
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }

  .icon-report, .icon-delete {
    display: none;
  }

  &:hover {
    .icon-report, .icon-delete {
      display: inline;
    }
  }
}

.comment--highlighted {
  background: #efefef;
}

.comment__meta {
  margin-left: 40px;
  line-height: 32px;
}

.comment__time {
  color: $textcolor-light;
}


// -----------------------------------------------------------------------------
// Comment form
// -----------------------------------------------------------------------------

.comment-form {
  overflow: hidden;
  margin: 15px 60px 0 60px;
  padding-bottom: 15px;

  button {
    float: left;
    padding: 0 15px;
    height: 38px;
    margin: 0;
  }

  input {
    width: 100%;
    padding: 5px 10px;
    height: 38px;
    box-sizing: border-box;
    border-color: $bordercolor-medium !important;
    border-width: 1px 0 1px 1px !important;
    box-shadow: none;

    &:focus, &:hover {
      background: $bgcolor-light;
    }
  }

  @include mobile {
    margin: 0 10px;
    padding-bottom: 0;

    input {
      border-width: 1px !important;
    }

    button {
      float: left;
      margin: 10px 0;
    }
  }
}

.comment-form__input-wrapper {
  float: left;
  width: 350px;

  @include mobile {
    width: 100%;
  }
}


.timeline, .moment, .moments {
  .btn.btn--like {
    height: 53px;
    line-height: 53px;
  }
}

// -----------------------------------------------------------------------------
// Comment form
// -----------------------------------------------------------------------------

.likes {
  overflow: hidden;
  padding: 15px 10px;

  ul {
    margin: 0;
    display: inline;
  }

  li {
    display: inline-block;
    &:before {
      content: ', ';
    }

    &:last-child:before { content: ' and '; padding-left: 4px; }
    &:first-child:before { content: ''; }
  }
}

.likes--none { padding: 0; }

.likes__more {
  font-size: 14px;
  color: $textcolor-light;
}

.likes__liked {
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  margin-right: 12px;
  float: left;
  color: $textcolor-red;

  &:hover, &:focus { color: $textcolor-alternate-red; }
}
