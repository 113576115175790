// -----------------------------------------------------------------------------
// ACCOUNT PAGE
// -----------------------------------------------------------------------------

.account {
  .section {
    position: relative;
    padding: 40px;
    border-bottom: solid 1px $bordercolor-light;

    h2 {
      margin: 0;
      padding: 0;
      color: $textcolor-black;
      text-align: left;
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 1.6rem;
    }

    p {
      margin: 0;
      padding: 0;
      line-height: 1.6rem;
      color: $textcolor-medium;
    }
  }

  .profile-summary {
    .avatar {
      float: left;
      width: 160px;
      height: 160px;

      img {
        padding: 10px;
        width: 134px;
        height: 134px;
        border: solid 1px $bordercolor-light;
      }
    }

    .surround {
      margin-left: 190px;
      height: 160px;
    }

    .button { margin-top: 20px; }
  }

  .camera-summary {
    padding: 20px 40px;

    .camera {
      margin-bottom: 30px;

      &:last-child { margin-bottom: 0; }
    }

    .clip-img {
      float: left;
      width: 48px;
      height: 60px;
    }

    .surround {
      margin-left: 70px;
      height: 60px;
    }

    p { font-size: 0.85rem; }
  }

  .stats {
    padding: 0;

    li {
      padding: 40px;
      font-variant: small-caps;
      color: $textcolor-light;

      span {
        display: block;
        font: 3rem $font-heading; font-weight: 700;
        color: $textcolor-black;
      }
    }

    .progressbar { width: 100%; }

    .status-description {
      padding: 10px 10px 40px;
      font-variant: small-caps;
      color: $textcolor-light;
    }
  }
}
