.public-timeline-description {
  position: relative;
  margin-bottom: 12px;
  padding: 14px 20px;
  background: $bgcolor-medium;
  font-size: 0.9rem;
  color: $textcolor-medium;

  .icon-close {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    color: $textcolor-medium;
  }
}
