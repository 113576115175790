.notifications {
  position: absolute;
  left: 50%;
  z-index: 10;
  margin-top: -10px;
  transform: translate(-50%, 0);
  width: 320px;
  max-width: 320px;
  background: #fff;
  border: solid 1px $bordercolor-medium;
  border-radius: 10px;
  overflow: hidden;

  .notifications--inner {
    border-radius: 10px;
    overflow: hidden;
  }

  .wrapper {
    width: 320px;
    padding-right: 45px;
    max-height: 360px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 320px;
  }

  &:before {
    position: absolute;
    z-index: 12;
    top: -6px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    content: " ";
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent $bgcolor-white transparent;
  }

  &:after {
    position: absolute;
    z-index: 11;
    top: -8px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    content: " ";
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $bordercolor-medium transparent;
  }
}

.notifications--inner .notifications--item {
  margin: 0;
  padding: 0;
  background: #fff;
  text-align: left;
  line-height: 1;
  height: auto;
  display: block;
  position: relative;

  .notifications--item__container {
    padding: 10px;
    border-bottom: solid 1px $bordercolor-medium;

    &.unseen { background: #f5f5f5; }
  }

  &:last-child .notifications--item__container { border-bottom: none; }

  img {
    max-height: 50px;
    max-width: 50px;

    &.image-type-circle {
      border: solid 1px $bordercolor-medium;
      border-radius: 50%;
    }
  }

  .target, .sender {
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;

    a { display: block; }
  }

  .text {
    display: inline-block;
    box-sizing: border-box;
    padding: 0 10px;
    width: 245px;
    min-height: 50px;
    font-size: 0.8rem;
    font-weight: normal;
    line-height: 1.2rem;
    overflow: hidden;

    span { color: $textcolor-medium; }
  }

  .has-target .text {
    width: 190px;
  }

}

.notifications--trigger { position: relative; }

.notifications--trigger__badge {
  position: absolute;
  display: inline-block;
  top: 14px;
  right: 0;
  width: 25px;
  text-align: left;
  color: #e26060;
  font-weight: bold;
  font-size: 0.8rem;
}
