.photo-grid {
  margin: 0;
  padding: 10px 0 0 10px;
  min-height: 600px;
  background: $bgcolor-white;
  line-height: 0;
  user-select: none;

  .item {
    display: inline-block;
    position: relative;
    padding: 0 7px 7px 0;

    .image {
      display: inline-block;
      overflow: hidden;
      position: relative;
      background: $bgcolor-iceblue;
    }

    img {
      display: block;
      vertical-align: bottom;
      width: 100%;
      height: 100%;
      max-height: 450px;
      box-sizing: border-box;
      outline: none;
      cursor: pointer;

      &:hover { opacity: 0.95; }
    }

    .select-area {
      position: absolute;
      right: 7px;
      bottom: 7px;
      width: 50px;
      height: 50px;
      background: url(/assets/img/transparent.gif);
      cursor: pointer;

      .select {
        position: absolute;
        top: 14px;
        right: 12px;
        bottom: 12px;
        left: 14px;
        border-radius: 50px;
        border: 2px solid $bordercolor-white;
        opacity: 0;
      }

      i {
        display: none;
        line-height: 21px;
        text-align: center;
        color: $textcolor-white;
      }
    }

    &:hover, &.selected {
      .icons, .select { opacity: 1; }
    }

    &.selected .select {
      background: $bgcolor-alternate-red;
      i { display: block; }
    }

    .icons {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 30px;
      opacity: 0;

      li {
        position: relative;
        height: 30px;
        line-height: 30px;
        list-style: none;
        color: #fff;
        text-shadow: 0 0 11px rgba(0, 0, 0, .6);
        text-align: center;
      }

      span {
        position: absolute;
        top: 42.5%;
        left: 32px;
        opacity: 0;
        transform: translate(0, -50%);
        font-size: 14px;
        font-variant: small-caps;
        font-weight: 100;
        text-shadow: none;
        white-space: nowrap;
      }
    }

    .double-tapped:hover + span { opacity: 1; }

    .star {
      font-size: 18px;
      cursor: pointer;

      + span {
        animation: fade-out 5s;
      }

    }
  }
}

@keyframes fade-out {
  0%   { opacity: 1; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}
