.profiles .content-plate { margin-bottom: 40px; }

.profile__presentation {
  padding: 40px 0;

  img { border-radius: 50%; }
  h1, h2 { margin: 0; padding: 0; }
  h1 { padding: 20px 0 6px; }

  h2 {
    font-size: 1.2rem;
    color: $textcolor-medium;
  }
}

.profile__no-public-moments {
  padding: 20px;
  border-top: solid 1px $bordercolor-light;
}
