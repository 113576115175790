// -----------------------------------------------------------------------------
// TOOLTIPS
// -----------------------------------------------------------------------------

.tooltip {
  display: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin: 0;
  padding: 8px 15px;
  transform: translate(-50%, 0);
  box-sizing: border-box;
  background: $bgcolor-white;
  border: solid 1px $bordercolor-light;
  box-shadow: 1px 1px 2px rgba(29, 29, 29, 0.06);
  font: 12px $font-body;
  font-weight: 500;
  color: $textcolor-medium;
  line-height: 1;

  &:before {
    position: absolute;
    z-index: 15;
    top: -4px;
    left: 50%;
    margin-left: -4px;
    width: 0;
    height: 0;
    content: " ";
    border-style: solid;
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent $bgcolor-white transparent;
  }

  &:after {
    position: absolute;
    z-index: 14;
    top: -6px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    content: " ";
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent $bordercolor-light transparent;
  }
}

@keyframes fadeInTooltip {
  0% { opacity: 0; }
  60% { opacity: 0; }
  100% { opacity: 1; }
}

i.title:hover:not(.disabled), a.title:hover:not(.disabled) {
  position: relative;
  z-index: 100;

  .tooltip {
    display: inline-block;
    animation-duration: .6s;
    animation-name: fadeInTooltip;
  }
}

// -----------------------------------------------------------------------------
// NOTIFICATIONS
// -----------------------------------------------------------------------------

.notice {
  position: fixed;
  top: 95px;
  z-index: 300;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: $font-body;
  font-size: 14px;

  ul {
    margin: 0 auto;
    padding: 0 80px 0 310px;
    max-width: 775px;
  }

  li {
    position: relative;
    background: $bgcolor-dark;

    &.ng-enter, &.ng-leave {
      transition: 0.3s linear all;
    }

    &.ng-enter, &.ng-leave.ng-leave-active { opacity: 0; }
    &.ng-leave, &.ng-enter.ng-enter-active { opacity: 1; }
  }

  p {
    margin: 0;
    padding: 0 70px 0 30px;
    line-height: 50px;
    color: $textcolor-white;
  }

  i {
    position: absolute;
    top: 0;
    right: 30px;
    cursor: pointer;
    font-size: 18px;
    line-height: 50px;
    color: $textcolor-white;
  }

  .confirm {
    padding: 0 20px;
    cursor: pointer;
    color: $textcolor-alternate-red;
  }
}

.account-login .notice {
  top: 10px;

  ul {
    padding: 0 30px;
    max-width: 1030px;
    box-sizing: border-box;
  }
}

// -----------------------------------------------------------------------------
// MODAL
// -----------------------------------------------------------------------------

.modal {
  position: fixed;
  z-index: 4000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: $font-body;

  &.large {
    .content { width: 900px; }
  }

  .icon-close {
    position: absolute;
    top: 20px;
    right: 20px;
    border: 2px solid $textcolor-red;
    border-radius: 100px;
    padding: 5px;
    font-size: 0.9rem;
    color: $textcolor-red;
    cursor: pointer;
  }

  .background {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  p {
    font-size: 0.9rem;
    text-align: left;
  }

  .content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 480px;
    background: $bgcolor-white;
    font-weight: 300;

    h2 {
      text-align: left;
      font-family: $font-heading; font-weight: 700;
      font-size: 0.9rem;
      color: $textcolor-dark;
    }

    .question { padding: 20px 40px; }

    .options {
      padding: 20px 30px;
      border-top: solid 1px $bordercolor-light;

      .fancy-select { width: 100%; }

      input[type="text"], input[type="password"], input[type="email"] {
        display: block;
        width: 100%;
        box-sizing: border-box;
      }

      .charcount {
        position: relative;

        .counter {
          position: absolute;
          top: 0;
          right: 0;
          padding: 3px 4px;
          font-family: $font-heading; font-weight: 700;
          font-size: 0.8rem;
          color: $textcolor-light;
        }
      }
    }

    .actions {
      padding: 20px 30px;
      border-top: solid 1px $bordercolor-light;
      text-align: right;
      overflow: hidden;
    }

    .success {
      text-align: center;
      color: $textcolor-alternate-red;
    }

    .cancel { margin-right: 20px; }
    .delete {
      overflow: hidden;
      width: 140px;
    }
  }
}

.video-modal .content {
  max-width: 1280px;
  width: 90%;
  height: 85%;
  top: 45%;

  .video-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
  }

  videogular {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video { width: 100%; }
}

// -----------------------------------------------------------------------------
// LIGHTBOX
// -----------------------------------------------------------------------------

.lightbox-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  z-index: 1999;
  transition: background .3s;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  opacity: 1 !important;
  z-index: 2000;
  width: 100%;
  height: 100%;

  .lightbox__photo  {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;

    > div {
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &:first-child { left: -100%; }
    &:last-child { left: 100%; }

    .highres {
      position: absolute;
      z-index: 1001;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
      }
    }

    .link {
      position: absolute;
      z-index: 1002;
      bottom: 40px;
      left: 50%;
      padding: 6px 16px;
      transform: translate(-50%, 0);
      background: rgba(255, 255, 255, 0.8);
      border: solid 1px $bordercolor-light;
      border-radius: 6px;

      &:hover { background: rgba(255, 255, 255, 0.9); }
    }
  }
}

.tagger {
  width: 220px;
  border-radius: 3px;
  position: fixed;
  z-index: 2000;

  &:before {
    @include arrow(5px, #fff);
  }
}

.tagger--up {
  margin-top: -32px;

  &:before {
    @include arrow-down(5px, #fff);
  }


  .tagger__results {
    position: absolute;
    width: 100%;
    bottom: 30px;
  }
}

input[type=text].tagger__input {
  height: 32px;
  line-height: 32px;
  padding: 0 30px 0 10px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  border: none !important;

  &.searching {
    background: #fff url('../img/spinner.gif') 195px center no-repeat;
  }
}

.tagger__results {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
  margin-top: -2px;
  background: #fff;
}

.tagger__person {
  line-height: 30px;
  height: 30px;
  padding: 5px 10px;
  vertical-align: top;
  cursor: pointer;
  border-top: 1px solid #ececec;

  span {
    float: left;
    width: 145px;
    overflow: hidden;
  }

  b {
    font-weight: 900;
  }

  &:hover {
    background: #efefef;
  }

  &.selected {
    background: #e0e0e0;
  }
}
.tagger__face {
  height: 100%;
  margin-right: 5px;
  float: left;
}

.tagger-info {
  background: #fff;
  opacity: 0.4;
  border-radius: 10px;

  list-style: none;

  li {
    float: left;
    width: 50px;
    padding: 0;
    height: 30px;
    line-height: 30px;
    text-align: center;

    &:first-child { border-radius: 0 10px 10px; }
    &:last-child { border-radius: 0 10px 10px; }

  }

  &:hover {
    opacity: 1;
  }
}

.lightbox__tags li {
  $lightbox__tags: #181d20;
  $lightbox__tags--hover: #181d20;
  $lightbox__tags__text: #fff;

  position: absolute;
  z-index: 2000;
  list-style: none;

  > span {
    display: block;
    background: $lightbox__tags;
    color: $lightbox__tags__text;
    padding: 7px 10px;
    border-radius: 3px;
    transition: all .2s;
    font-size: 14px;
  }

  &:before {
    transition: all .2s;
    @include arrow(5px, $lightbox__tags);
  }

  &.editable:hover > span, .tagging & > span  {
    padding-right: 30px;
    .lightbox__tags__delete {
      display: inline !important;
    }
  }
}

.lightbox__tags__delete {
  color: #4f5e66;
  position: absolute;
  top: 6px;
  right: 13px;
}

.lightbox__share-info {
  position: fixed;
  z-index: 1000;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  color: transparent;
  transition: all 1s;

  &:not(.ng-hide) {
    color: #000;
  }
}
