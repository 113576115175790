// -----------------------------------------------------------------------------
// MOMENT HEADER
// -----------------------------------------------------------------------------

.moment-display .content-head {
  .middle-container {
    width: auto;

    .middle {
      padding: 0 30px;

      p {
        display: block;
        margin: 5px 0 0;
        font: 12px $font-body;

        span { display: inline-block; }
      }

      time {
        display: inline-block;
        color: $textcolor-black;
      }
    }
  }
}

.moment-nav {
  position: fixed;
  background: $bgcolor-white;
  height: 45px;
  border-radius: 100px;
  box-shadow: 0 2px 7px rgba(0,0,0,.1);
  list-style: none;
  opacity: 1;

  li {
    float: left;
    height: 100%;
    line-height: 45px;
    cursor: pointer;

    &:hover { color: $textcolor-red; }
  }

  i {
    display: block;
    line-height: 45px;
    font-size: 20px;
    padding: 0 15px;
  }

  .disabled { color: $textcolor-verylight; }

  li:first-child i { padding-left: 30px; }

  li:last-child {
    border-radius: 0 100px 100px 0;
    padding-right: 12px;
  }
}

.moment-nav--moment {
  z-index: 10;
  top: 79px;
  left: 50%;
  transition: top cubic-bezier(.39,.38,.3,1.31) .2s, opacity linear .1s;

  li:last-child {
    border-radius: 0 100px 100px 0;
    font-size: 14px;
    padding: 0 24px;
    float: right;
    background: #f8f8f8;
    border-left: 1px solid #eeeff0;
  }

  &.ng-hide { top: 0; opacity: 0; }
}

.moment-nav--video {
  bottom: -80px;
  left: 50%;
  transform: translate(-50%, 0);

  li:last-child {
    border-radius: 0 100px 100px 0;
    font-size: 14px;
    padding: 0 14px 0 10px;
    float: right;
    border-left: 1px solid #eeeff0;
  }
}

.moment-nav--lightbox {
  $moment-nav-lightbox-height: 40px;

  z-index: 3000;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  height: $moment-nav-lightbox-height;
  padding: 0 10px;
  margin: 0 !important;

  i {
    line-height: $moment-nav-lightbox-height;
    font-size: 16px;
    padding: 0;
    display: inline-block;
  }

  li {
    line-height: $moment-nav-lightbox-height;
    font-size: 14px;
    padding: 0 10px;
    vertical-align: top;
  }

  li:first-child i {
    padding-left: 0;
  }

  li:last-child {
    padding-right: 10px;
  }

  .icon-check {
    color: $bgcolor-green;
  }

  a {
    color: #000;
  }
}

.moment-nav--tagging {
  background: $bgcolor-verydark;
  color: $textcolor-white;

  .text,.text:hover {
    color: #afbfbe;
    cursor: inherit;
    border-right: 1px solid $bordercolor-dark;
  }
}

// -----------------------------------------------------------------------------
// LOADER
// -----------------------------------------------------------------------------

.placeholder {
  padding: 0 7px 7px 0;
  width: 33%;
  height: 300px;
  box-sizing: border-box;

  div {
    height: 100%;
    background: $bgcolor-iceblue;
    animation-duration: 1.8s;
    animation-name: changecolor;
    animation-iteration-count: infinite;
  }

  &.placeholder-1 div { animation-delay: 0s; }
  &.placeholder-2 { width: 25%; div { animation-delay: .3s; } }
  &.placeholder-3 { width: 42%; div { animation-delay: .6s; } }
  &.placeholder-6 { width: 25%; height: 280px; div { animation-delay: .9s; } }
  &.placeholder-5 { width: 40%; height: 280px; div { animation-delay: 1.2s; } }
  &.placeholder-4 { width: 35%; height: 280px; div { animation-delay: 1.5s; } }

  @keyframes changecolor {
    0% { background: #f1f5f8; }
    50% { background: $bgcolor-iceblue; }
    100% { background: $bgcolor-iceblue; }
  }
}

// -----------------------------------------------------------------------------
// MOMENT VIEWER
// -----------------------------------------------------------------------------

.moment-display .select-mode {
  .item .select { opacity: 1; }

  i:after {
    display: inherit !important;
    bottom: 14px;
  }
}

.moment-display {
  .item .star.icon-star { opacity: 1; }

  .split-mode {
    .item {
      .select, .star { opacity: 0 !important; }

      .split-select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .top, .bottom, .scissor { opacity: 0; }

        .top, .bottom {
          position: absolute;
          width: 7px;
          height: 50%;
          background-repeat: no-repeat;
        }

        .top {
          top: -4px;
          background: url(/assets/img/splitter-top.png);
        }

        .bottom {
          bottom: 2px;
          background: url(/assets/img/splitter-bottom.png) bottom left;
        }

        .scissor {
          position: absolute;
          z-index: 1;
          top: 50%;
          right: -24px;
          margin-top: -27px;
          width: 54px;
          height: 54px;
          background: $bgcolor-white;
          box-shadow: 0 3px 4px rgba(29, 29, 29, 0.35);
          border-radius: 100px;

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
            color: $textcolor-red;
          }
        }
      }

      &:hover, &.split-selected {
        .split-select {
          .top, .bottom { right: 4px; }
          .top, .bottom, .scissor { opacity: 1; }
        }

        + .item .split-select {
          .top, .bottom {
            opacity: 1;
            left: -3px;
          }

          .top { background-position: top right; }
          .bottom { background-position: bottom right; }
        }
      }
    }
  }
}

.modal.combine-moments {
  .options {
    padding: 40px 0;
    background: $bgcolor-body;
  }

  .mergable-moments {
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 20px;
      padding: 5px 0 5px 5px;
      width: 246px;
      box-sizing: border-box;
      background: $bgcolor-white;
      border: solid 1px $bordercolor-light;
      font: .9rem $font-heading; font-weight: 700;
      color: $textcolor-dark;

      .moment { height: 120px; }

      time {
        display: block;
        padding: 20px 0;
      }

      button {
        margin-bottom: 20px;
        padding: 8px 25px;
      }

      &.current button {
        background: $bgcolor-white !important;
        border-color: transparent;
        color: $textcolor-red !important;
      }

      &.active, &.current { border: solid 2px $bordercolor-red; }
      &.inactive { opacity: 0.8; }
      &.inactive:hover { opacity: 1; }
    }
  }
}

// -----------------------------------------------------------------------------
// MOMENT FOOTER
// -----------------------------------------------------------------------------

.moment-display .content-foot {
  .back { border-right: none; }
}
