.auth__logotype {
  background: url(/assets/img/narrative-logo-login.png) no-repeat left top;
  background-size: 137px 43px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 43px;
  width: 137px;
  padding-left: 40px;
  line-height: 36px;
  font-weight: bold;
  color: #fff;
}

.auth__flash {
  padding: 0 0 1rem;
  font-size: 0.9rem;
}

.auth__flash span { display: block; }

.auth__flash--error { color: $textcolor-red; }
.auth__flash--success { color: $textcolor-green; }

.account-login {
  background: url('/assets/img/background.jpg') center top;
  background-size: cover;

  /* We've had a weird bug that where it seems like the auth controllers body
     classes are set on other routes, this is a very ugly way to prevent this
     from causing weird effects */
  &.shared, &.timeline, &.video, &.moment, &.favorites, &.profiles, &.moments, &.account {
    background: $bgcolor-body;
  }
}

.account-login__overlay {
  background: rgba(0,0,0,0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

// -----------------------------------------------------------------------------
// CREATE ACCOUNT / LOGIN
// -----------------------------------------------------------------------------

.auth-wrapper {
  max-width: 1030px;
  margin: 100px auto;
  overflow: hidden;
  position: relative;
  z-index: 11;
}

.auth-headings {
  color: #fff;
  position: absolute;
  top: 320px;
  left: 0;
  font-size: 16px;

  h1 {
    font-size: 3em;
    color: #fff;
    margin: .55em 0;
  }

  p {
    margin: 0;
  }
}

.auth__inner {
  float: right;
}

.auth-box {
  margin: 0 30px 20px 0;
  background: #fff;
  border-radius: 6px;
  width: 336px;
  padding: 25px 20px 7px 20px;
  box-sizing: border-box;
  font-size: 16px;

  a {
    margin-top: 2px;
  }

  h2 {
    padding: 0;
    margin: 0 0 16px;
    font-size: 1.125em;
  }

  p {
    font-size: .875em;
    margin: 10px 0 11px;
  }

  .fancy-select {
    padding: 0;
    width: 100%;
    height: 34px;
    box-sizing: border-box;

    &:hover { background-color: #fff; }

    select { padding: 7px 10px; }
  }

  input[type="checkbox"] {
    display: none;

    + .checkbox {
      position: relative;
      display: inline-block;
      overflow: hidden;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
      padding: 0;
      width: 18px;
      height: 18px;
      border: solid 1px $bordercolor-medium;
      border-radius: 3px;
      outline: medium none;
    }

    &:checked + .checkbox {
      color: $textcolor-green;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e60a";
        font-size: 18px;
      }
    }
  }

  .input {
    position: relative;
    background: $bgcolor-white;
    border: solid 1px #fff;
    overflow: hidden;
    clear: both;
    text-align: left;
    margin-bottom: 10px;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -9px;
    width: 26px;
    height: 18px;
    font-size: 16px;
    color: $bordercolor-light;
  }

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 0 55px 0 10px;
    width: 100%;
    height: 34px;
    line-height: 34px;
  }

  button {
    padding: 0;
    border-radius: 3px;
  }

  .login-form__submit {
    width: 100%;
  }

  small {
    display: block;
    font-size: .75em;
    margin-bottom: 1em;
  }

  .select-container {
    overflow: hidden;
    max-height: 100px;
    transition: max-height .2s;

    &.ng-hide {
      max-height: 0;
    }
  }
}

.login-form__submit {
  width: 82px;
  float: left;
  line-height: 32px;
  height: 35px;
}

.register-form__submit {
  width: 100%;
  line-height: 48px;
  height: 50px;
}

// -----------------------------------------------------------------------------
// SMARTPHONES
// -----------------------------------------------------------------------------

@media (max-width: 600px) {
  .account-login {
    .mobile-only {
      margin: 20px;
      padding: 20px;
      background: $bgcolor-white;
      border: solid 1px #d7d7d7;
      box-sizing: border-box;
      text-align: left;
      color: #969eab;
    }

    .auth-wrapper { margin: 30px 0; }
    .auth-headings { display: none; }
    .auth__inner { float: none; }
    .auth__logotype {
      position: static;
      margin: 20px;
    }

    .auth-box {
      margin: 0 auto 20px;
      width: 90%;
      padding: 25px 20px 7px 20px;
    }

    input, .fancy-select { border-radius: 2px; }
  }
}
