.scrub {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 9px;
  background: #000;

  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  i { display: none; }

  &.pause {

    i {
      left: 64.18764302059496%;
      position: absolute;
      bottom: 9px;
      background-color: #fff;
      padding: 5px;
      font-size: 10px;
      border-radius: 10px;
      margin-left: -23px;
      font-style: normal;
      display: inherit;

      &:before {
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        background-color: #fff;
        padding: 5px;
        font-size: 10px;
        border-radius: 10px;
        font-style: normal;
        text-align: center;
      }

      &:after {
        content: '';
        border: 3px solid $bordercolor-dark;
        border-width: 0 3px;
        width: 4px;
        height: 11px;
        position: absolute;
        left: 13px;
        top: 5px;
      }
    }
  }

  .scrub__progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -9px;
    background: #fff;
    height: 8px;
    border-top: 1px solid $bordercolor-scrub;

    @include mobile {
      border-bottom: 1px solid $bordercolor-scrub;
    }

    span {
      width: 4px;
      height: 9px;
      position: absolute;
      left: 0;
      bottom: 0px;
      background-color: $bgcolor-red;
    }
  }

  .pause .scrub__progress i { display: inherit; }
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
