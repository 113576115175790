.moments-wrapper + .moments-loader p { display: inherit; }

// -----------------------------------------------------------------------------
// NO MOMENTS
// -----------------------------------------------------------------------------

.empty-timeline {
  text-align: center;
  line-height: 5em;

  p {
    margin: 0;
    padding: 0;
  }
}

@keyframes clip-color {
  0%   { background-image: url('/assets/img/clips/white.jpg'); }
  30%  { background-image: url('/assets/img/clips/white.jpg'); }
  33%  { background-image: url('/assets/img/clips/black.jpg'); }
  63%  { background-image: url('/assets/img/clips/black.jpg'); }
  66%  { background-image: url('/assets/img/clips/red.jpg'); }
  97%  { background-image: url('/assets/img/clips/red.jpg'); }
  100% { background-image: url('/assets/img/clips/white.jpg'); }
}

.no-activated-clip {
  background-image: url('/assets/img/clips/white.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 110px 114px;
  width: 200px;
  min-height: 150px;

  animation-name: clip-color;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

// -----------------------------------------------------------------------------
// MOMENT SHOWCASE
// -----------------------------------------------------------------------------

.timeline {

  > div {
    margin: 0 auto 200px;
  }
}

.moment {
  .photo {
    float: left;
    position: relative;
    margin: 0;
    box-sizing: border-box;

    div {
      position: absolute;
      top: 0;
      right: 5px;
      bottom: 5px;
      left: 0;
      background-color: $bgcolor-iceblue;
      background-position: center center;
      background-size: cover;
    }
  }

  .photo-1x1 {
    width: 25%;
    padding-bottom: 25%;
  }

  .photo-2x1 {
    width: 50%;
    padding-bottom: 25%;
  }

  .photo-2x2 {
    width: 50%;
    padding-bottom: 50%;
  }

  .photo-4x2 {
    padding-bottom: 50%;
    width: 100%;
  }
}

.moment-showcase {
  position: relative;
  margin-bottom: 15px;
  border: 1px solid $outlinecolor-light;
  background-color: $bgcolor-white;

  .moment-covers {
    position: relative;
    padding-top: 50.7%;
    background-image: url('/assets/img/loading-moment.gif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
      content: " "; // Needed for the :before to work
      display: block;
    }
  }

  &.moment-video .moment-covers:after {
    content: '';
    background: url('/assets/img/video_play.svg') center center no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .moment {
    position: absolute;
    top: 0;
    width: 100%;
    display: block;
    padding: 10px 5px 5px 10px;
    overflow: hidden;
    outline: none;
    box-sizing: border-box;

    &:hover { background-color: $bgcolor-iceblue; }
  }

  &.moment-real {
    border-bottom: none;
  }
}

.moment-social-wrapper {
  overflow: hidden;
  border-bottom: 1px solid $bordercolor-light;
}

.momment__inner-container {
  padding: 10px;
  border-top: 1px solid $bordercolor-light;
  background: #fff;
  position: relative;
  z-index: 1;
}

.moment-visibility {
  font: 1rem $font-heading; font-weight: 700;
  font-variant: small-caps;
  color: $textcolor-green;
  line-height: 74px;
  display: block;
}

.private-moments .moment-visibility {
  line-height: 74px;
  padding: 0 20px;
}
