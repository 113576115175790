$anon-border: #dde7ed;
$anon-background: #f9f9f9;
$anon-topbar: 73px;
$anon-sidebar: 421px;
$block-border: #dde7ed;
$color-primary: #ED5B5B;
$font-primary: 'Open Sans', Sans-serif;

.shared header > .center {
  max-width: none;
}

.scrub-view {
  top: $anon-topbar;
  position: absolute;
  right: $anon-sidebar + 1px;

  @include tablet {
    right: 40%;
  }

  @include mobile {
    height: 400px;
    width: 100%;
    top: 0;
    right: 0;
    position: relative;
  }
}

.scrub-view--moment {
  @extend .scrub-view;
  width: auto;
  background-color: $anon-background;
}

.scrub-view--video {
  @extend .scrub-view;
  left:0;
  bottom: 0;

  .overlayPlayContainer .play:before {
    font-size: 60px;
  }
}

.sidebar-anon {
  position: absolute;
  width: $anon-sidebar;
  background: #fff;
  border-left: 1px solid $anon-border;
  right: 0;
  top: $anon-topbar;
  bottom: 0;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include tablet {
    width: 40%;
  }

  @include mobile {
    width: 100%;
    position: static;
    border: none;
  }

  .caption { font-size: 1.25em; }

  .likes {
    border-bottom: 1px solid $anon-border;
    flex-shrink: 0;
    flex-grow: 1;

    font-family: $font-primary;
    font-size: .9em;

    padding: 15px 40px;
  }
}

.sidebar-anon__meta {
  position: fixed;
  margin-top: -50px;
  padding: 20px 40px;
}


.sidebar-anon__info {
  padding: 25px 40px 80px;
  box-sizing: border-box;
  align-self: flex-start;
  flex-shrink: 0;
  flex-grow: 10;
  height: 240px;

  @include tablet {
    padding-bottom: 40px;
  }

  @include mobile {
    height: auto;
  }
}


.sidebar-anon__comments {
  overflow-y: scroll;
  padding: 0;
  font-family: $font-primary;

  .comments__count { display: none; }

  .comments { padding: 0; }

  .comment {
    border-bottom: 1px solid $block-border;
    margin: 0 40px;
    padding: 25px 0 18px;
    font-family: $font-primary;

    &:last-child {
      border: none;
    }

    img {
      width: 36px;
      height: 36px;
      border-radius: 50px;
    }

    .comment__meta {
      margin: 9px 0 5px 50px;
      line-height: 36px;
    }

    p {
      font-size: 14.4px;
      line-height: 22px;
      margin-left: 50px;
    }

    @include tablet {
      margin: 0 20px;
    }

  }

  .comment__time {
    float: right;
    font-family: $font-primary;
    color: #D8D8D8;
  }

  @include mobile {
    position: static;
    overflow-y: auto;
  }
}

.sidebar-anon__heart {
  position: absolute;
  top: 32px;
  left: 25px;
}

.sidebar-anon__foot {
  position: relative;
  border-top: 1px solid $block-border;

  flex-shrink: 0;

  width: 100%;
  height: 80px;

  .comment-form {
    padding: 15px 20px 15px 0;
    margin: 0 0 0 64px;;
    box-sizing: border-box;

    .comment-form__input-wrapper {
      float: none;
      width: inherit;
    }

    input {
      font-family: $font-primary;
      height: 50px;
      line-height: 50px;
      width: 100%;
      box-sizing: border-box;
      background: #FAFAFA;
      border: none;
      border-radius: 5px;
    }

    .fake-input {
      font-family: $font-primary;
      height: 50px;
      line-height: 42px;
      width: 100%;
      box-sizing: border-box;
      background: #FAFAFA;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      color: #999;
    }

    button {
      position: absolute;
      padding: 0 15px;
      height: 50px;
      margin: 0;
      top: 15px;
      right: 20px;
      width: 10px;
      border: none;
      text-indent: -1000em;
      background: transparent !important;
    }

    @include mobile {
      button {
        display: inherit;
        position: absolute;
        left: -1000px;
        top: -1000px;
      }
    }
  }
}

.avatar {
  border-radius: 100px;
  float: left;
}

.sidebar-anon__author__meta {
  margin-left: 56px;
  min-height: 40px;
}

.username, .location {
  font-family: $font-primary;
  font-size: 0.9em;
  display: block;
  line-height: 20px;
}

.location {
  color: $textcolor-light;
  font-size: 0.8em;
}

.caption {
  font-family: $font-primary;
  margin-top: 30px;
}

.sidebar-anon__author {
  box-sizing: border-box;
  min-height: 50px;

  @include mobile {
    width: 100%;
    padding: 20px;
    margin-top: 60px;
    background: #fff;
  }
}

.shared {
  .main-navigation {
    max-width: none;
    border-bottom: 1px solid $anon-border;
    z-index: 10;
    padding: 0 40px;

    ul {
      li {
        margin-left: 10px;

        &:first-child { margin: 0; }
      }
    }

    @include mobile {
      padding: 0 10px;

      button, .btn--primary {
        padding: 7px 10px;
        font-size: 14px;
        line-height: 1.3;
        height: auto;
      }
    }

  }
}

.btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 9px 26px;
  border: none;
  font-family: $font-primary;
  font-weight: normal;
  font-size: 15px;
  text-align: center;

  &.btn--primary {
    color: #fff;
    background: $color-primary;
  }

  &.btn--link {
    background: transparent;
    color: #000;
  }

  &.btn--big {
    font-size: 1.12rem;
    line-height: 68px;
    height: 68px;
    padding: 0 20px;
    min-width: 220px;
  }

  &.btn--facebook {
    background: #2c4388;
  }

  &.btn--like {
    width: auto;
    min-width: 0;
    background: transparent;
    padding: 9px 20px;
    font-size: 1.5rem;

    &:hover i {
      color: $textcolor-red;
    }
  }
}

.modal--shared {
  text-align: center;
  font-size: 16px;

  @include mobile {
    position: absolute;
    .background { position: fixed; }
  }

  p {
    text-align: center;
    font-size: 1rem;
    font-family: $font-primary;
  }

  .heading-img {
    width: 98px;
    height: 98px;
    border-radius: 100px;
    margin-bottom: 30px;
    margin-top: 60px;
  }

  p.heading {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 0;
  }

  .signin-or-create .heading {
    margin-bottom: 50px;

    @include mobile {
      margin-bottom: 30px;
    }
  }

  .content {
    width: 660px;
    min-height: 530px;
    padding: 50px;
    box-sizing: border-box;
    background-size: 330px 500px;
    border-radius: 8px;

    .options {
      border: none;
      padding: 0;
    }

    @include mobile {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      border-radius: 0;
      width: 100%;
      height: auto;
      min-height: 100%;

      .btn {
        margin-bottom: 20px;
      }
    }
  }

  .content--create-account {
    padding: 50px;
    background: #fff;
  }

  .register-form {
    .input {
      float: left;
      width: 50%;
      padding: 0 12px 0 0;
      box-sizing: border-box;

      &:nth-child(2n) {
        padding: 0 0 0 12px;
      }

      @include mobile {
        float: none;
        width: 100%;
        padding: 0;

        &:nth-child(2n) {
          padding: 0;
        }
      }
    }

    .fancy-select {
      width: 100%;
    }

    .register-form__submit {
      width: 100%;
    }

    .align-center {
      display: block;
      clear: both;
      font-size: 0.9rem;

      br {
        display: none;
      }
    }
  }

  .login-form, .forgot-password-form {
    margin: 0 auto;
    max-width: 300px;
  }

  .login-form__submit {
    width: 100%;
    float: none;
  }

  .auth__flash {
    margin: 0 auto;
    text-align: center;
  }
}

.download-hint {

  display: none;

  .download-hint__inner {
    position: fixed;
    z-index: 2000;
    bottom: 0;
    width: 100%;
    padding: 40px;
    text-align: center;
    box-sizing: border-box;
    background-color: #fff;
  }

  p {
    margin: 0;
  }

  a {
    margin: 30px 0 0;
    display: block;
  }

  .ingress {
    font-size: 1.25rem;
  }

  .btn {
    margin: 50px 0 0;
  }

  .download-hint--info {
    display: none;
  }

  .device-ios &, .device-android & {
    display: inherit;
  }
}

.download-hint__swipe {
  display: none;
  position: absolute;
  z-index: 150;
  top: 340px;
  left: 50%;
  background: rgba(0,0,0, 0.8);
  color: #fff;
  text-align: center;

  box-sizing: border-box;
  padding-top: 60px;
  font: 14px $font-primary;
  border-radius: 8px;

  width: 192px;
  height: 192px;
  transform: translate(-50%, -50%);
  opacity: 1;

  &.ng-hide {
    transition:0.5s linear all;
    opacity:0;
  }
}

.scrub-swipe {
  display: none;
  position: absolute;
  z-index: 200;
  top: 140px;
  left: 0;
  width: 100%;
  height: 400px;
  background: transparent;
  user-select: none;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.device-ios {
  .download-hint, .download-hint--ios, .download-hint__swipe, .scrub-swipe {
    display: inherit;
  }
}

.device-android {
  .download-hint, .download-hint--android, .download-hint__swipe, .scrub-swipe {
    display: inherit;
  }
}

// -----------------------------------------------------------------------------
// LOADING SPINNER
// -----------------------------------------------------------------------------

.loader {
  margin: 30% auto;
  display: block;
  width: 251px;
  height: 60px;
  font-size: 15px;

  $loader-max-height: 3em;
  $loader-min-height: 2em;
  $loader-bar-spacing: 0.5em;
  $loader-bar-width: 0.3em;

  &__text {
    display:inline-block;
    float: left;
    line-height: $loader-max-height;
  }

  &__spinner--wrapper {
    margin-left:$loader-bar-spacing * 2 + $loader-bar-width;
    margin-top: ($loader-max-height - $loader-min-height) / 2;
    display:inline-block;
    float: left;
    height: $loader-max-height;
  }

  &__spinner,
  &__spinner:before,
  &__spinner:after {
    background: $bgcolor-red;
    width: $loader-bar-width;
    height: $loader-min-height;
    animation: load1 1s infinite ease-in-out;
  }
  &__spinner:before,
  &__spinner:after {
    position: absolute;
    top: 0;
    content: '';
  }
  &__spinner:before {
    left: - ($loader-bar-spacing + $loader-bar-width);
    animation-delay: -0.32s;
  }
  &__spinner {
    text-indent: -9999em;
    display:inline-block;
    position: relative;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  &__spinner:after {
    left: $loader-bar-spacing + $loader-bar-width;
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0 $bgcolor-red;
      height: $loader-min-height;
    }
    40% {
      box-shadow: 0 ($loader-min-height - $loader-max-height) / 2 $bgcolor-red;
      height: $loader-max-height;
    }
  }
}
