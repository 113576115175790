// -----------------------------------------------------------------------------
// LABELS
// -----------------------------------------------------------------------------

label {
  display: block;
  height: auto;

  span { padding: 0 0 10px; }
}

// -----------------------------------------------------------------------------
// BUTTONS
// -----------------------------------------------------------------------------

button, .button, .btn {
  display: inline-block;
  padding: 12px 30px;
  background: $bgcolor-red;
  border: solid 1px $bordercolor-red;
  outline: none;
  cursor: pointer;
  font: 0.9rem $font-heading; font-weight: 700;
  color: $textcolor-white;

  &.cancel, &.cancel:hover {
    background: transparent;
    border: none;
    color: $textcolor-dark;
  }

  &[disabled] {
    background: $bgcolor-medium !important;
    border: solid 1px $bordercolor-medium;
    color: $textcolor-light !important;
  }

  &:hover {
    background: $bgcolor-alternate-red;
    color: $textcolor-white;
  }
}

// -----------------------------------------------------------------------------
// TEXT FIELDS
// -----------------------------------------------------------------------------

input {
  display: block;
  margin-bottom: 20px;
  font-family: $font-body;
}

.input {
  margin-bottom: 20px;
}

input[type=text], input[type=password], input[type=email], input[type=number], textarea {
  display: inline-block;
  margin: 0;
  padding: 0 20px;
  vertical-align: middle;
  height: 68px;
  background: $bgcolor-white;
  border: 1px solid $bordercolor-medium;
  outline: none;
  line-height: 68px;
  font-size: 1rem;
  color: $textcolor-dark;
  font-family: $font-body;
  box-sizing: border-box;
  width: 100%;

  &[disabled] {
    background: $bgcolor-light;
    color: $textcolor-light;
  }

  &:focus {
    background: $bgcolor-lightmedium;
  }
}

textarea {
  height: inherit;
}

// -----------------------------------------------------------------------------
// CHECKBOXES
// -----------------------------------------------------------------------------

.fancy-checkbox {
  display: inline-block;
  line-height: 1;

  $disabled: $bgcolor-gray;
  $enabled : $bgcolor-alternate-red;
  $animation: .25s;

  input[type="checkbox"] {
    display: none;

    + .checkbox {
      display: inline-block;
      appearance: none;

      background: $disabled;
      background: linear-gradient(to left, $disabled 0%,$disabled 50%,$enabled 50%,$enabled 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$enabled', endColorstr='$disabled',GradientType=1 );

      background-size: 200% 100%, 100% 100%;
      background-position: 100% 0, 0 0;
      border-radius: 26px;

      cursor: pointer;
      height: 28px;
      position: relative;

      transition: background-position $animation;
      width: 54px;
      vertical-align: top;
      outline: none;
      margin: 0;
      padding: 0;

      &:after {
        background: $bgcolor-white;
        border-radius: 100px;

        content: '';
        display: block;
        height: 24px;
        width: 24px;
        left: 2px;
        position: absolute;
        top: 2px;

        transition: left $animation;
      }
    }

    &:checked + .checkbox {
      border-color: $enabled;
      background-position: 0 0, 0 0;

      &:after {
        left: inherit;
        left: 27px;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// SELECTION FIELDS
// -----------------------------------------------------------------------------

.fancy-select {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  height: 68px;
  background: white url(/assets/img/arrow-down.png) right center no-repeat;
  border: 1px solid $bordercolor-gray;
  line-height: 1.428571429;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: $textcolor-dark;

  &:hover, &:active { background: $bgcolor-light url(/assets/img/arrow-down.png) right center no-repeat; }

  select {
    appearance: none;
    background: transparent;
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 12px;
    width: 110%;
    border: none;
    outline: none;
    font-size: 14px;
  }

  &.focus { background-color: $bgcolor-gray; }
}

// -----------------------------------------------------------------------------
// VALIDATION
// -----------------------------------------------------------------------------

input[type=text], input[type=password], input[type=email], input[type=number] {
  &.ng-invalid { border: solid 1px $bordercolor-darkred; }
}

div.error { color: $textcolor-darkred; }


// -----------------------------------------------------------------------------
// FORM OPTIONS
// -----------------------------------------------------------------------------
.options {
  font-size: .9rem;

  label {
    display: inline-block;
    position: relative;
    font-size: .9rem;
    font-weight: bold;
    color: $textcolor-dark;
    line-height: 22px;

    span {
      position: absolute;
      top: 60%;
      left: 30px;
      transform: translate(0, -50%);
      width: 150px;
    }
  }

  a { color: $textcolor-dark; }
}
