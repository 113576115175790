$tablet: 1024px;
$mobile: 767px;

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet}+1px) {
    @content;
  }
}
