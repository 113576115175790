@mixin arrow-top($left, $background) {
  position: absolute;
  z-index: 15;
  top: -10px;
  left: $left;
  margin-left: -10px;
  width: 0;
  height: 0;
  content: " ";
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent $background transparent;
}

@mixin arrow-center($size, $background) {
  position: absolute;
  z-index: 15;
  top: -$size;
  left: 50%;
  width: 0;
  height: 0;
  content: " ";
  border-style: solid;
  border-width: 0 $size $size $size;
  border-color: transparent transparent $background transparent;
  transform: translate(-50%, 0);
}

@mixin arrow-down-center($size, $background) {
  position: absolute;
  z-index: 15;
  bottom: -$size;
  top: auto;
  left: 50%;
  width: 0;
  height: 0;
  content: " ";
  border-style: solid;
  border-width: $size $size 0 $size;
  border-color: $background transparent transparent transparent;
  transform: translate(-50%, 0);
}

@mixin arrow($size, $background) {
  position: absolute;
  z-index: 15;
  top: -$size;
  left: -$size;
  width: 0;
  height: 0;
  content: " ";
  border-style: solid;
  border-width: 0 $size $size $size;
  border-color: transparent transparent $background transparent;
}

@mixin arrow-down($size, $background) {
  position: absolute;
  z-index: 15;
  bottom: -$size;
  top: auto;
  width: 0;
  height: 0;
  content: " ";
  border-style: solid;
  border-width: $size $size 0 $size;
  border-color: $background transparent transparent transparent;
}
