// -----------------------------------------------------------------------------
// VIEW DISPLAY
// -----------------------------------------------------------------------------

.left { float: left; }
.right { float: right !important; }
.center { margin: 0 auto; }

.clear:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: ".";
  height: 0;
}

div.inline { display: inline-block; }

.invisible { visibility: hidden; }

// -----------------------------------------------------------------------------
// POSITIONING
// -----------------------------------------------------------------------------

.container { position: relative; }

.middle-container {
  display: table;
  width: 100%;
  height: 100%;

  .middle {
    display: table-cell;
    height: 100%;
    width: 290px;
    vertical-align: middle;
  }
}

.flex-vcenter {
  display: flex;
  flex-direction: column;
  align-content: center; /* align vertical */
  justify-content: center;
}

// -----------------------------------------------------------------------------
// VISIBILITY
// -----------------------------------------------------------------------------

.hide { display: none !important; }

.fill-parent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.desktop-only { display: inherit; }
.mobile-only { display: none; }

.device-ios, .device-android {
  .desktop-only { display: none; }
  .mobile-only { display: inherit; }
}

// -----------------------------------------------------------------------------
// LIST FORMATTING
// -----------------------------------------------------------------------------

ul, ol {
  margin: 0;
  padding: 0;

  &.inline {
    li { display: inline-block; }
  }

  &.no-bullet { list-style: none; }
}

// -----------------------------------------------------------------------------
// ACTIONS
// -----------------------------------------------------------------------------

.action {
  cursor: pointer;
  visibility: hidden;
}

.action--text {
  font-size: 12px;
  color: $textcolor-light;

  &:hover {
    color: $bgcolor-red;
  }

  &:before {
    content: '(';
  }

  &:after {
    content: ')';
  }
}


// -----------------------------------------------------------------------------
// BORDERS
// -----------------------------------------------------------------------------

.no-border {
  border: none !important;
}

.nonselectable {
  user-select: none;
}

.device-ios .android--only { display: none !important; }
.device-android .ios--only { display: none !important; }

// -----------------------------------------------------------------------------
// CLOAKING ELEMENTS
// -----------------------------------------------------------------------------

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/* The following will also hide animations unless these are explicitly set
   to be have some sort of display. */
.ng-hide-add, .ng-hide-remove, .ng-show-add, .ng-show-remove {
  display: none !important;
}
