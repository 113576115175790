body.photo {
  .notice ul { padding: 0; }
}

.photo-overlay {
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $bgcolor-white;
}

// -----------------------------------------------------------------------------
// TOP MENU
// -----------------------------------------------------------------------------

.photo-overlay .meta {
  position: absolute;
  top: 30px;
  left: 0;
  height: 40px;
  width: 100%;
  font-size: 24px;

  i {
    cursor: pointer;
    color: $textcolor-medium;

    &:hover { color: $textcolor-red; }
    &:after { bottom: -20px; }
    .tooltip { bottom: -45px; }
  }

  ul {
    margin-right: 50px;
    line-height: 40px;

    li { padding: 0 10px; }
  }

  .photo-overlay-close {
    border-left: 1px solid $textcolor-verylight;
    padding-left: 30px;
    margin-left: 30px;
  }
}

// -----------------------------------------------------------------------------
// PHOTO NAVIGATION
// -----------------------------------------------------------------------------

.photo-overlay {
  .icon-arrow_left, .icon-arrow_right {
    position: absolute;
    top: 50%;
    margin-top: -45px;
    width: 90px;
    height: 90px;
    border: 1px solid $bordercolor-light;
    cursor: pointer;
    line-height: 90px;
    font-size: 26px;
    user-select: none;
    color: $textcolor-medium;

    &:hover { color: $textcolor-red; }
  }

  .icon-arrow_left {
    left: 0;
    border-width: 1px 1px 1px 0;
  }

  .icon-arrow_right {
    right: 0;
    border-width: 1px 0 1px 1px;
  }
}

// -----------------------------------------------------------------------------
// IMAGE DISPLAY
// -----------------------------------------------------------------------------

.photo-overlay {
  .image {
    padding: 100px 100px;
    box-sizing: border-box;
  }

  .container { height: 100%; }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }

  .timestamp {
    position: relative;
    z-index: 500;
  }
}
